import React from "react"
import { Link, graphql } from "gatsby"

import {
    MdArrowBack,

} from 'react-icons/md';


import SEO from "../components/seo"
import Layout from "../components/layout"

const Marcas = ({ data: { image1, products_genica,products_goa,products_iancarina,products_mondelez,products_pepsico,products_st_morits,products_puig,products_gomby,products_cosmelar,products_alimentos_polar } }) => {



    return (
        <Layout changeHeader={2}>
            <SEO title={`Marcas`} />

            <section className="hero-wrap hero-wrap-2 mb-2 top_btn" style={{ backgroundImage: `url(${image1.childImageSharp.fluid.src})`, height: '450px' }} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-1" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                            <Link to="/" className="icon ml-5 btn btn-primary" title="Regresar">
                                <MdArrowBack
                                    color="#fff"
                                    size="2.3em"
                                />
                            </Link>

                        </div>
                        <div className="col-md-11 text-center">
                            <h1 className="mb-2 bread" data-sal="slide-left" data-sal-duration="2000" data-sal-easing="ease">Nuestras Marcas </h1>
                            <p className="breadcrumbs"><span className="mr-2">

                                <Link className="" to="/">Inicio</Link> > </span> <span>Nuestras Marcas</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section contact-section ftco-no-pb" id="contact-section">
                <div className="container">
                    <div className="row justify-content-center mb-5 pb-3">
                        <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1800" data-sal-easing="ease">
                            {/* <h2 className="mb-4">Nuestras Marcas</h2> */}
                            <p>En Distribuidora Yonlui, c.a contamos con una variedad de selección de marcas líderes en el mercado, para ofrecer a nuestros clientes los mejores productos</p>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-3 text-center p-5 mt-0 bg_gris" data-sal="zoom-in" data-sal-duration="1800" data-sal-easing="ease">
                            <img src={products_pepsico.childImageSharp.fluid.src} className="img-fluid" alt="Ilustración de registro" />
                        </div>
                        <div className="col-md-3 text-center p-5 mt-0 bg_gris" data-sal="zoom-in" data-sal-duration="1800" data-sal-easing="ease">
                            <img src={products_mondelez.childImageSharp.fluid.src} className="img-fluid" alt="Ilustración de registro" />
                        </div>
                        <div className="col-md-3 text-center p-5 mt-0 bg_gris" data-sal="zoom-in" data-sal-duration="1800" data-sal-easing="ease">
                            <img src={products_iancarina.childImageSharp.fluid.src} className="img-fluid" alt="Ilustración de registro" />
                        </div>
                        <div className="col-md-3 text-center p-5 mt-0 bg_gris" data-sal="zoom-in" data-sal-duration="1800" data-sal-easing="ease">
                            <img src={products_genica.childImageSharp.fluid.src} className="img-fluid" alt="Ilustración de registro" />
                        </div>
                        <div className="col-md-3 text-center p-5 mt-0 bg_gris" data-sal="zoom-in" data-sal-duration="1800" data-sal-easing="ease">
                            <img src={products_goa.childImageSharp.fluid.src} className="img-fluid" alt="Ilustración de registro" />
                        </div>
                        <div className="col-md-3 text-center p-5 mt-0 bg_gris" data-sal="zoom-in" data-sal-duration="1800" data-sal-easing="ease">
                            <img src={products_st_morits.childImageSharp.fluid.src} className="img-fluid" alt="Ilustración de registro" />
                        </div>
                        <div className="col-md-3 text-center p-5 mt-0 bg_gris" data-sal="zoom-in" data-sal-duration="1800" data-sal-easing="ease">
                            <img src={products_puig.childImageSharp.fluid.src} className="img-fluid" alt="Ilustración de registro" />
                        </div>
                        <div className="col-md-3 text-center p-5 mt-0 bg_gris" data-sal="zoom-in" data-sal-duration="1800" data-sal-easing="ease">
                            <img src={products_alimentos_polar.childImageSharp.fluid.src} className="img-fluid" alt="Ilustración de registro" />
                        </div>
                        <div className="col-md-3 text-center p-5 mt-0 bg_gris" data-sal="zoom-in" data-sal-duration="1800" data-sal-easing="ease">
                            <img src={products_gomby.childImageSharp.fluid.src} className="img-fluid" alt="Ilustración de registro" />
                        </div>
                        <div className="col-md-3 text-center p-5 mt-0 bg_gris" data-sal="zoom-in" data-sal-duration="1000" data-sal-easing="ease">
                            <img src={products_cosmelar.childImageSharp.fluid.src} className="img-fluid" alt="Ilustración de registro" />
                        </div>

                    </div>
                </div>
            </section>




        </Layout>
    )
}

export default Marcas

export const pageQuery = graphql`
  query MarByDev {

    image1: file(relativePath: { eq: "banner_mar_reg.png" }) {
        childImageSharp {
            fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    products_genica: file(relativePath: { eq: "products_genica.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    products_goa: file(relativePath: { eq: "products_goa.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    products_iancarina: file(relativePath: { eq: "products_iancarina.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    products_mondelez: file(relativePath: { eq: "products_mondelez.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    products_pepsico: file(relativePath: { eq: "products_pepsico.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    products_st_morits: file(relativePath: { eq: "products_st_morits.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    products_puig: file(relativePath: { eq: "products_puig.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    products_gomby: file(relativePath: { eq: "products_gomby.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    products_cosmelar: file(relativePath: { eq: "marca_cosmelar.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    products_alimentos_polar: file(relativePath: { eq: "products_alimentos_polar.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }

  }
`
